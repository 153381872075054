@charset "UTF-8";
@import 'variables';
@import '~bootstrap/scss/bootstrap';
@import 'https://unpkg.com/swiper@7/swiper-bundle.min.css';

html,
body {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

body .row {
    width: 100%;
    margin: 0;
}

body .container {
    max-width: 1330px;
}

h1,
h2,
h3,
h4 {
    font-family: "Montserrat", sans-serif;
}

h1 {
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    font-size: 44px;
    font-weight: bold;
    letter-spacing: 1px;
}

h2 {
    position: relative;
    font-size: 26px;
    font-weight: 500;
    color: #393939;
    letter-spacing: 2px;
}

h3 {
    font-size: 18px;
    font-weight: 500;
}

a {
    font-size: 14px;
}

a:hover {
    text-decoration: none;
}

a,
button {
    font-family: "Montserrat", sans-serif;
    cursor: pointer;
}

button.btn-blue {
    color: #06749F;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
}

header {
    height: 85px;
    position: relative;
    font-family: "Montserrat", sans-serif;
}

header .navbar-nav .nav-item .nav-link {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #393939;
    padding-right: 15px;
    padding-left: 15px;
}

.dropdown-menu {
    border: none;
}

header .navbar-nav .dropdown-menu .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1rem;
    clear: both;
    font-size: 14px;
    font-weight: 500;
    color: #393939;
    letter-spacing: 1px;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

header .navbar-nav .dropdown-menu .dropdown-item.active {
    color: #06749f;
}

header .extra-links {
    align-items: center;
}

header .extra-links a {
    padding-left: 15px;
    color: #06749f;
    font-size: 14px;
    display: flex;
    align-items: center;
}

img {
    margin-right: 8px;
}

header .extra-links a img {
    margin-right: 7px;
}

header .extra-links a:hover {
    text-decoration: none;
}

header .header-search .input-group input {
    border-radius: 28px 0 0 28px;
    box-shadow: 0 1px 14px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    border: none;
}

header .header-search .input-group .input-group-append .btn {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    border-radius: 0 28px 28px 0;
    background-color: #ff711d;
    border: none;
    padding: 7px 20px;
}

header .header-search ::-webkit-input-placeholder {
    font-size: 12px;
    color: #848484;
}

header .header-search ::-moz-placeholder {
    font-size: 12px;
    color: #848484;
}

header .header-search :-ms-input-placeholder {
    font-size: 12px;
    color: #848484;
}

header .header-search ::-ms-input-placeholder {
    font-size: 12px;
    color: #848484;
}

header .header-search ::placeholder {
    font-size: 12px;
    color: #848484;
}

header .header-search :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 12px;
    color: #848484;
}

header .header-search ::-ms-input-placeholder {
    /* Microsoft Edge */
    font-size: 12px;
    color: #848484;
}

header .dropdown-toggle::after {
    display: none;
}

header .navbar-light .navbar-nav .active>.nav-link {
    color: #06749f;
}

footer {
    padding: 35px 50px;
    background-color: #262626;
    color: #cbcbcb;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
}

footer .left {
    line-height: 25px;
}

footer .right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

footer .right a {
    color: #cbcbcb;
    padding: 0 10px;
}

footer .right a:last-child {
    padding-right: 0;
}

section {
    flex-grow: 1;
}

section.bg-gray-curves {
    background-color: #f1f4f7;
    position: relative;
}

section.section-landing {
    position: relative;
    width: 100%;
    min-height: 330px;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
}

section.section-landing .container {
    min-height: 330px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: #fff;
}

// section.section-landing:after {
//   content: "";
//   position: absolute;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   width: 100%;
//   height: 42px;
//   background: url("/themes/implication/assets/images/arch-hero-image.svg");
//   background-size: cover;
//   background-repeat: no-repeat;
// }

section.section-landing .subtitle {
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    font-family: "Montserrat", sans-serif;
    font-size: 26px;
    font-weight: 500;
    letter-spacing: 1px;
    display: block;
}

section.section-landing .btn-orange {
    margin-top: 50px;
}

section.section-slider-posts {
    margin-bottom: 0;
    padding: 0 32px;
}

section.section-slider-posts .thumbnail {
    width: 100%;
    height: 220px;
    background: #ddd;
    border-radius: 10px;
    box-shadow: 0 1px 14px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
}

section.section-slider-posts .icons {
    display: flex;
}

section.section-slider-posts .icons .icon {
    padding-right: 20px;
}

section.section-slider-posts .icons .icon img {
    margin-right: 7px;
}

section.section-slider-posts .icons .icon span {
    font-size: 13px;
    color: #676767;
}

section.section-posts .row {
    margin: 0;
}

section.section-posts:nth-child(odd) {
    background-color: #f1f4f7;
    padding: 20px 0;
    position: relative;
}

section.section-categories.start-with-gray {
    > section.section-posts:nth-child(odd) {
        background-color: #fff;
        padding: 20px 0;
        position: relative;
    }

    > section.section-posts:nth-child(even) {
        background-color: #f1f4f7;
        padding: 20px 0;
        position: relative;
    }
}

section.section-posts .row .thumbnail {
    width: 100%;
    height: 180px;
    border-radius: 10px;
    background: #ddd;
    box-shadow: 0 1px 14px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
}

section.section-posts .row .icons {
    display: flex;
}

section.section-posts .row .icons .icon {
    padding-right: 20px;
}

section.section-posts .row .icons .icon img {
    margin-right: 7px;
}

section.section-posts .row .icons .icon span {
    font-size: 13px;
    color: #676767;
}

section.section-posts .btn-orange {
    display: table;
    margin: 50px auto 0 auto;
}

section.section-categories .row {
    margin: 0;
}

section.section-categories .row .col-3 {
    margin-bottom: 40px;
}

section.section-categories .row .thumbnail {
    width: 100%;
    height: 180px;
    border-radius: 10px;
    background: #ddd;
    box-shadow: 0 1px 14px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
}

section.newsletter {
    position: relative;
    padding: 100px 0 150px 0;
    background: url("/themes/implication/assets/images/photos/photo-newsletter.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}


section.newsletter h2:after {
    display: none;
}

section.newsletter input {
    border: none;
    width: calc(100% - 215px);
    padding: 5px 10px;
    border-radius: 25px 0 0 25px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.16);
}

section.newsletter .btn-newsletter {
    border-radius: 0 25px 25px 0;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.16);
    background-color: #ff711d;
    color: #fff;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 500;
    padding: 7px 20px;
}

section.newsletter ul {
    margin-top: 30px;
    margin-bottom: 30px;
}

section.newsletter ::-webkit-input-placeholder {
    font-size: 13px;
    color: #848484;
}

section.newsletter ::-moz-placeholder {
    font-size: 13px;
    color: #848484;
}

section.newsletter :-ms-input-placeholder {
    font-size: 13px;
    color: #848484;
}

section.newsletter ::-ms-input-placeholder {
    font-size: 13px;
    color: #848484;
}

section.newsletter ::placeholder {
    font-size: 13px;
    color: #848484;
}

section.newsletter :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 13px;
    color: #848484;
}

section.newsletter ::-ms-input-placeholder {
    /* Microsoft Edge */
    font-size: 13px;
    color: #848484;
}

.page-home .section-landing {
    min-height: 540px;
    background-position: center;
    background-size: cover;
}

.page-home .section-landing .container {
    min-height: 540px;
}


.page-communication .section-landing {
    background-position: center;
    background-size: cover;
}

.page-communication .section-categories {
    margin-bottom: 0;
}

.page-communication .section-categories h3 {
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 1px;
}

.page-communication .section-categories:after {
    display: none;
}

.page-category .section-landing {
    background-position: center;
    background-size: cover;
}

.page-category .section-posts {
    margin-bottom: 0;
    padding: 50px 0;
}

.page-category .section-posts h3 {
    font-size: 14px;
}

.page-category .section-posts:before {
    display: none;
}

.page-category .section-posts:after {
    display: none;
}

.carousel-control-prev {
    left: -70px;
    bottom: 0;
    width: 80px;
    padding: 0 0 70px 20px;
}

.carousel-control-next {
    right: -75px;
    bottom: 0;
    width: 100px;
    padding-bottom: 70px;
}

.carousel-control-prev-icon {
    background-image: url("/themes/implication/assets/images/arrow-left.svg");
}

.page-about .section-landing {
    min-height: 400px;
    //   background: url("/themes/implication/assets/images/photos/header-about-us.jpg");
    background-position: center;
    background-size: cover;
}

.page-about .categorie-img img {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 1px 14px 0 rgba(0, 0, 0, 0.2);
}

.page-about .categorie-text h3 {
    font-size: 30px;
    font-weight: 600;
    position: relative;
    margin-bottom: 50px;
}

.categorie-img img {
    max-width: 100%;
}

.carousel-control-next-icon {
    background-image: url("/themes/implication/assets/images/arrow-right.svg");
}

.category-navigation {
    margin-top: 60px;
    margin-bottom: 50px;
    color: #979797;
}

.category-navigation a {
    font-size: 13px;
    color: #979797;
    text-decoration: none;
}

.page-category-page .section-posts h3 {
    font-size: 14px;
}

.content-source {
    color: #6f6f6f;
    font-size: 15px;
    margin: 20px 0 40px 0;
}

.section-resources h3 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 30px;
}

.resource-box {
    margin-top: 30px;
    margin-right: 46px;
}

.resource-box h4 {
    font-size: 18px;
    font-weight: 500;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    border: none;
}

.nav-tabs {
    padding-left: 0;
    border-radius: 4px;
    border-bottom: none;
}

.nav-tabs .resource-link {
    border: none;
    font-size: 14px;
    color: #06749f;
    font-weight: 500;
    background-color: white;
    text-decoration: none;
    margin-right: 16px;
}

.nav-tabs .resource-link.active {
    color: white;
    border-radius: 4px;
    background-color: #06749f;
    padding: 12px 14px;
}

.nav-tabs .nav-link {
    border: none;
    font-size: 14px;
    color: #06749f;
    font-weight: 500;
    background-color: #EFEFEF;
    padding: 21px 51px;
    border-bottom: 1px solid #E7E7E7 !important;

}

.nav-tabs .nav-link.active {
    color: #06749f;
    border-radius: 4px;
    background-color: white;
    border-bottom: 1px solid #E7E7E7 !important;
}

.resource-file {
    margin-top: 20px !important;
    border-radius: 10px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
    padding: 10px;
}

.resource-file .title-pdf {
    font-size: 15px;
    font-weight: 600;
    margin-left: 10px;
    margin-bottom: 0;
}

.resource-file a {
    font-size: 15px;
    font-weight: normal;
    color: #06749f;
}

.resource-file a img {
    margin-right: 10px;
}

.like-image {
    position: absolute;
    top: 12px;
    right: 0;
    height: 30px;
    width: 30px;
}

.margin-top {
    margin-top: 24px;
}

.label-text {
    font-family: "Montserrat", sans-serif;
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 1px;
}

.sub-title {
    font-size: 18px;
    font-family: "Montserrat", sans-serif;
    color: #393939;
}

.paragraph {
    white-space: normal;
    font-size: 15px;
    color: #393939;
    line-height: 22px;
}

// bootstrap promene

.navbar-brand {
    margin-top: 8px;
}

.btn-close {
    color: #6F6F6F;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
}

.tab-content>.tab-pane {
    padding: 8px 32px;
}

.modal-content {
    margin-top: 40px;
    width: auto;
    height: auto;
    border-radius: 10px;
}

.modal-body {
    padding: 0;
}

a.btn-orange,
button.btn-orange {
    border-radius: 27.5px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.16);
    background-color: #ff711d;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 8px 32px;
}

.btn:hover {
    text-decoration: none;
}

header .header-search .input-group .input-group-append .btn:hover {
    background-color: #e85c0f;
    color: #fff;
}

section.section-posts .btn-orange:hover {
    background-color: #e85c0f;
    color: #fff;
}

.btn-orange:hover {
    background-color: #e85c0f;
    color: #fff;
}

.btn-newsletter:hover {
    background-color: #e85c0f;
    color: #fff;
}


.btn-light:disabled {
    color: #212529;

    .btn:hover {
        text-decoration: none;
    }

    .btn-orange:hover {
        text-decoration: none;
        background-color: #e85c0f;
    }

}

button.btn-blue a {
    color: #06749F;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    text-decoration: none;
}

.container-fluid {
    margin-bottom: 18px;
}

@media (max-width: 991px) {

    .modal-content {
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        position: fixed;
        display: flex;
        overflow-y: scroll;
        margin-top: 0;
        border-radius: unset;
    }

    .nav-tabs .nav-item.modal-item {
        width: 50%;
    }

    .hide-nav-modals {
        display: none !important;
    }

    .navbar-toggler-icon {
        justify-content: end;
    }

    .categories {
        text-align: center;
    }

    .icons-center {
        justify-content: center;
    }

    .post-title {
        margin-top: 8px;
        margin-bottom: 0;
    }

    .navbar-toggler-right {
        margin-left: auto !important;
    }

    .navbar-collapse {
        position: absolute;
        top: 64px;
        right: 15px;
    }

    .navbar {
        top: 8px;
    }

    .pagination-link {
        font-size: 11px;
    }
}

@media (min-width: 992px) {

    .hide-modals {
        display: none;
    }

    .navbar-toggler-center {
        justify-content: center !important;
    }
}

.navbar-collapse {
    background-color: #fff;
    z-index: 1005;
}

@media (max-width: 768px) {

    .navbar-brand img {
        width: 180px;
    }

    .display-grid {
        display: grid;
    }

    .nav-item.bottom-margin {
        margin-bottom: 12px;
    }

    ;

    .nav-tabs .resource-link.active {
        padding: 8px 6px;
    }

    .resource-item {
        margin-top: 16px;
    }

    .input-group {
        top: 32px;
    }

    .justify-content-md-center {
        justify-content: center !important;
    }

    section.section-landing:after {
        height: 0;
    }

    section.newsletter {
        margin-top: 32px;
    }

    section.newsletter:after {
        height: 0;
    }

    .footer-responsive {
        flex-direction: column-reverse;
    }

    .footer-margin {
        display: none;
    }

    .footer-nav {
        margin-bottom: 16px;
        display: grid;
    }

    footer .right {
        justify-content: left;
        text-align: left;
    }
}

.post-title {
    color: #393939;
    text-decoration: none;
    margin-bottom: 0;
    margin-top: 12px;
}



.icons-margin {
    margin-top: -16px;
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
}

.pagination-link {
    padding-right: 0;
    color: #06749F;
}

.pagination-link.disabled {
    color: #979797;
    font-weight: 400;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
    margin-left: 5px;
}

.page-item {
    margin-right: 3px;
}

.page-link {
    position: relative;
    display: block;
    padding: 5px 10px;
    border-radius: 4px;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: #fff;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 400;
    border: none !important;
}

.page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
}

.page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}


.page-item.active .page-link {
    z-index: 3;
    color: #FFFFFF;
    background-color: #06749F;
    border-radius: 4px;
    padding: 5px 10px;
    font-weight: 500;
}

.page-item.disabled {
    color: #979797;
    font-weight: 400;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
}

.category-img {
    //   border-radius: 4px;
    margin-bottom: 5px;
    margin-top: 16px;
    width: 100%;
    object-fit: cover;
}


@media (max-width: 576px) {
    .nav-tabs .nav-link {
        padding: 21px 47px;
    }
}


.offcanvas-header {
    display: none;
}

@media (max-width: 992px) {
    .category-title {
        margin-top: 32px;
    }

    .offcanvas-header {
        display: block;
    }

    .navbar-collapse {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 100%;
        width: 100%;
        padding-right: 1rem;
        padding-left: 1rem;
        overflow-y: hidden;
        visibility: hidden;
        background-color: #fff;
        transition: 0.2s;
    }

    .collapse:not(.show) {
        display: block !important;
    }

    .navbar-collapse.show {
        visibility: visible;
        transform: translateX(-100%);
    }

    .navbar-nav.responsive {
        height: 100%;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        left: 0;
    }

    .container {
        padding: 18px;
    }

    footer .right a {
        padding: 3px;
    }

    .footer-nav a {
        padding-right: 0;
    }
}

.footer-margin {
    margin: 0 8px;
}

.navbar-close-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    font-size: 44px;
    padding: 8px 8px;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
    outline: none;
}

.navbar-close {
    padding: 0.25rem 0.75rem;
    font-size: 1.125rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    position: absolute;
    right: 0;
    z-index: 1001;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
    outline: none;
}

.navbar-close:hover,
.navbar-close:focus {
    outline: none;
}

.swiper-button-next {
    top: 120px;
    right: -50px;
    color: #cbcbcb;
}

.swiper-button-prev {
    top: 120px;
    left: -50px;
    color: #cbcbcb;
}

.implication-slider {
    position: relative;

    h3 {
        font-size: 14px;
    }
}

[data-field-name*="navigation_hidden"] {
    display: none;
}

.container-gray {
    width: 100%;
    background-color: #f1f4f7;
    padding: 0 0 70px 0;

    > .container {
        box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1);
        max-width: 900px;
        margin: -50px auto 0;
        background-color: white;
        position: relative;
        border-radius: 16px;
        padding: 40px 40px 30px;
        line-height: 160%;
        font-size: 17px;
        color: #333;
    }
}
